main {
  margin-bottom: 200%;
}

.floating-menu {
  background-color: #fff;
  border-radius: 0px;
  z-index: 999;
  padding-top: 10px;
  padding-bottom: 10px;
  right: 0;
  position: fixed;
  display: inline-block;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  .main-menu {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }
  .main-menu li a {
    display: block;
    padding: 10px;
    border-radius: 50px;
    position: relative;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  .main-menu li a:hover {
    background: rgba(244, 244, 244, 0.822);
  }
  .menu-bg {
    background-color: transparent;
  }
  .ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  .ripple:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
  .ripple:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
  @keyframes glowing {
    0% { box-shadow: 0 0 -10px #4E2062; }
    40% { box-shadow: 0 0 20px #4E2062; }
    60% { box-shadow: 0 0 20px #4E2062; }
    100% { box-shadow: 0 0 -10px #4E2062; }
  }

  .button-glow {
    animation: glowing 2500ms infinite;
  }
}
